import React from 'react';
import { Button } from 'antd';
import SaleOrderDetailEditor from '../components/sale_order_detail_editor/SaleOrderDetailEditor';
import SaleConfirmDialog from '../sale_order_create/SaleOrderConfirmDialog';
import WebApi from '@/web/api';
import saleDetailMobx from '../sale_order_detail/saleDetailMobx';
import { Layout } from '@/components';

interface State {
  payAmount: string;
  loading: boolean
  dataSource: any[]
  customerName: string
  visible: boolean
  createdAt: string
  key: number
}

export default class SaleUpdate extends React.PureComponent<PagePropsType, State> {
  private orderProducts: any[] = []
  private editProduct = React.createRef<SaleOrderDetailEditor>();
  private initialDataSource: any[] = [];

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataSource: [],
      customerName: '',
      visible: false,
      createdAt: '',
      payAmount: '0.00',
      key: 1
    };
  }

  componentDidMount() {
    this.findProducts();
  }

  findProducts = () => {
    WebApi.sale_detail_update_option({
      saleOrderId: this.props.match?.params.id
    })
    .then((data: any) => {
      const dataSource = data.detailList.map(item => ({...item, orderPrice: item.price, orderUnitRatio: item.unitRatio}));
      this.initialDataSource = dataSource;
      this.setState({
        dataSource,
        payAmount: data.payAmount,
        customerName: data.customerName,
        createdAt: data.createdAt,
        key: this.state.key + 1
      });
    })
  };

  render() {
    const { loading, dataSource, customerName, visible, createdAt, key } = this.state;

    return <Layout.Page>
      <div style={{marginBottom: 20, display: 'flex'}}>
        <div style={{marginRight: 50}}><strong>订货单号: </strong>{this.props.match?.params.id}</div>
        <div style={{marginRight: 50}}><strong>客户: </strong>{customerName}</div>
        <div><strong>下单时间: </strong>{createdAt}</div>
      </div>
      <Layout flex1 overHidden flexColumn>
        <SaleOrderDetailEditor
          key={key}
          ref={this.editProduct}
          payAmount={this.state.payAmount}
          customerId={this.props.match?.params.id}
          dataSource={dataSource}
        />
      </Layout>
      <Layout.FootBar>
        <Button type="primary" ghost onClick={() => {
          this.editProduct.current?.reset(this.initialDataSource);
        }}>恢复初始</Button>,
        <Button type="primary" style={{marginLeft: 20}} onClick={this.clickSave}>确认修改</Button>
      </Layout.FootBar>
      <SaleConfirmDialog
        visible={visible}
        products={this.orderProducts}
        customerName={customerName}
        onCancel={() => this.setState({visible: false})}
        onOk={this.confirmUpdate}
      />
    </Layout.Page>
  }

  clickSave = () => {
    // 合并商品
    this.editProduct.current?.validateProducts().then(data => {
      this.orderProducts = data.map(item => {
        return {
          ...item,
          orderPrice: item.isGift ? 0 : item.orderPrice,
          payAmount: item.isGift ? 0 : item.payAmount,
        };
      });
      // 确认
      this.setState({
        visible: true,
      });
    });
  };

  confirmUpdate = () => {
    this.setState({
      visible: false,
    });
    const { match} = this.props;

    WebApi.sale_order_product_update({
      saleOrderId: match?.params.id,
      detailList: this.orderProducts.map(item => ({
        productId: item.productId,
        productName: item.productName,
        skuId: item.skuId,
        skuName: item.skuName,
        orderPrice: item.orderPrice,
        orderCount: item.orderCount,
        orderUnitId: item.orderUnitId,
        orderUnitName: item.orderUnitName,
        isGift: item.isGift,
        remark: item.remark || '',
      })),
    })
    .then(data => {
      showSuccess.save();
      this.findProducts();
      saleDetailMobx.findSaleDetail(this.props.match?.params.id);
    });
  }
}
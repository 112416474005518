import { Dialog, ViewTable } from "@/components";
import BigNumber from "bignumber.js";
import React from "react";

export default (props: {
  onOk(): void
  onCancel():void
  visible: boolean
  customerName: string
  address?: string
  remark?: string
  products: any[]
}) => {
  return <Dialog title="确认订单"
    block
    visible={props.visible}
    onOk={props.onOk}
    onCancel={props.onCancel}>
    <div>
      <div style={{marginBottom: 30}}>
        <div style={{marginBottom: 10}}>客户名称：<strong>{props.customerName}</strong></div>
        {
          props.address &&
          <div style={{marginBottom: 10}}>收货地址：<strong>{props.address}</strong></div>
        }
        {
          props.remark &&
          <div>订单备注：<strong>{props.remark}</strong></div>
        }
        <div>金额总计：<strong style={{color: '#ea3323', fontSize: 16}}>{props.products.reduce((pre, cur) => {
          return new BigNumber(cur.orderPrice).multipliedBy(cur.orderCount).plus(pre).toFixed(2);
        }, '0')}</strong></div>
      </div>
      <ViewTable
        dataSource={props.products}
        rowKey={(row: any) => row.skuId }
        columns={[
          {
            title: '商品名称',
            dataIndex: 'productName',
            width: 240,
            render: (v, row) => {
              return <div style={{display: 'flex', alignItems: 'center', lineHeight: 1}}>
                {
                  row.isGift === 1 &&
                  <i className="iconfont icon-zeng" style={{color: '#ea3323', fontSize: 14, lineHeight: 1, marginRight: 2}}/>
                }
                {v}
              </div>
            }
          },
          {
            title: '规格',
            dataIndex: 'skuName',
            width: 300,
          },
          {
            title: '价格（元）',
            dataIndex: 'orderPrice',
            align: 'right',
            width: 120,
          },
          {
            title: '数量',
            dataIndex: 'orderCount',
            align: 'right',
            width: 120,
          },
          {
            title: '计量单位',
            dataIndex: 'orderUnitName',
            align: 'right',
            width: 120,
          },
          {
            title: '小计（元）',
            align: 'right',
            width: 120,
            dataIndex: 'payAmount'
          },
          {
            title: '备注',
            dataIndex: 'remark',
            align: 'left',
            width: 'auto',
          },
        ]}
        pagination={false}
      />
    </div>
  </Dialog>
}
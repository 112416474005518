import React from 'react';
import { Input, Select, message, Checkbox, Dropdown, Menu } from 'antd';
import { InputNumber, ProductDialog, EditTable, Layout } from '@/components';
import BigNumber from 'bignumber.js';
import WebApi from '@/web/api';
import { LibISaleOrderDetail } from 'lib/interface/SaleOrderInterface';

interface ISaleOrderDetailEditProps {
  customerId: string;
  dataSource: LibISaleOrderDetail[];
  payAmount?: string;
  totalAmount?: string;
  onAdd?(): void;
  onChange(data: any, payAmount: string, totalAmount: string): void;
}

interface IState {
  dataSource: LibISaleOrderDetail[];
  payAmount: string;
  totalAmount: string;
  visible: boolean;
}
export default class SaleOrderDetailEdit extends React.PureComponent<ISaleOrderDetailEditProps, IState> {
  static defaultProps = {
    payAmount: '0.00',
    totalAmount: '0.00',
    onChange: () => {},
    dataSource: []
  };

  private productDialog = React.createRef<ProductDialog>()

  state: any
  constructor(props: ISaleOrderDetailEditProps) {
    super(props);
    this.state = {
      dataSource: props.dataSource || [],
      payAmount: props.payAmount,
      totalAmount: props.totalAmount,
      visible: false,
    };
  }

  render() {
    const { dataSource } = this.state;

    return (
      <Layout flex1 overHidden flexColumn>
        <ProductDialog
          attributes={['activity', 'skuWithStock', 'unit']}
          ref={this.productDialog}
          onOk={this.onModalOk}
        />

        <EditTable
          flex1
          onAdd={() => {
            this.props.onAdd && this.props.onAdd();
            if (!this.props.customerId) {
              message.warning('请先选择客户');
              return;
            }
            this.productDialog.current?.open()
          }}
          onMinus={(record, index) => {
            this.clickDelete(index);
          }}
          dataSource={dataSource}
          columns={[
            {
              title: '是否赠品',
              dataIndex: 'isGift',
              align: 'center',
              width: 70,
              render: (v, row, index) => {
                if (!row.productId) return null;
                return <Checkbox checked={v == 1} onChange={e => {
                  this.createDataSource(index, {
                    isGift: e.target.checked ? 1 : 0,
                  });
                }}/>
              },
            },
            // {
            //   title: '主图',
            //   dataIndex: 'logoImageUrl',
            // },
            {
              title: '商品名称',
              dataIndex: 'productName',
              width: 240,
              render: text => {
                return <div style={{padding: '0 8px'}}>{text}</div>
              },
            },
            {
              title: '规格',
              dataIndex: 'skuName',
              width: 360,
              render: (text, record, index) => {
                if (!record.productId) return null;
                return <div style={{padding: '0 8px'}}>
                  <Select value={record.skuId} style={{width: '100%'}}
                    placeholder="请选择规格"
                    options={record.skuOptions}
                    onChange={(value, options) => {
                      const sku = record.skuList.find(item => item.skuId === value);
                      this.onChangeSku(index, {
                        skuId: value,
                        skuName: sku.skuName,
                        price: sku.price,
                        orderPrice: sku.price,
                        originalPrice: sku.originalPrice,
                        orderCount: 1,
                        stockUsableCount: sku.stockUsableCount,
                      });
                    }}>
                  </Select>
                </div>
              },
            },
            // {
            //   title: '营销活动',
            //   width: 200,
            //   render: (v, row) => {
            //     if (!row.productId) return null;
            //     if (!row.activity) return '无';
            //     return row.activity.activityName
            //   },
            // },
            // {
            //   title: '原价',
            //   dataIndex: 'originalPrice',
            //   width: 100,
            //   align: 'right',
            //   render: (text, row) => {
            //     if (!row.productId) return null;
            //     return text || '--';
            //   },
            // },
            // {
            //   title: '价格（元）',
            //   dataIndex: 'price',
            //   width: 100,
            //   align: 'right',
            //   render: (v, row) => {
            //     if (!row.productId) return null;
            //     if (!row.skuId) return '--';
            //     return <div>
            //       {v}
            //       {
            //         row.activity?.activityName && <Tooltip overlay={<div>
            //           营销活动：{row.activity?.activityName}
            //         </div>}>
            //           <a style={{color: '#000', marginLeft: 10}}><QuestionCircleOutlined/></a>
            //         </Tooltip>
            //       }
            //     </div>;
            //   }
            // },
            // {
            //   title: '是否赠品',
            //   width: 100,
            //   align: 'center',
            //   dataIndex: 'isGift',
            //   render: (v, row, index) => {
            //     if (!row.productId) return null;
            //     return <Checkbox checked={v === 1} onChange={e => {
            //       this.createDataSource(index, {isGift: e.target.checked ? 1 : 0});
            //     }}/>
            //   }
            // },
            {
              title: '价格（元）',
              dataIndex: 'orderPrice',
              align: 'center',
              width: 150,
              render: (text, record, index) => {
                if (!record.productId) return null;
                if (record.isGift === 1) return '0.00';
                return <div className="g-flex-row g-align-items-center">
                  <InputNumber.Price
                    selected
                    value={text}
                    disabled={!record.skuId}
                    placeholder="填写价格"
                    onChange={value => {
                      this.onChangePrice(index, value);
                    }}
                  />
                  <Dropdown trigger={['click', 'hover']} placement="bottomCenter" overlay={
                    <Menu>
                      <Menu.Item disabled={!record.latestPrice} onClick={() => {
                        if (record.isGift) return;
                        record.orderPrice = record.latestPrice;
                        this.setState({
                          dataSource: [...dataSource],
                        });
                      }}>记忆价：{record.latestPrice || '--'}</Menu.Item>
                      <Menu.Item disabled={!record.price} onClick={() => {
                        record.orderPrice = record.price;
                        this.setState({
                          dataSource: [...dataSource],
                        });
                      }}>现价：{record.price}</Menu.Item>
                      <Menu.Item disabled={!record.originalPrice} onClick={() => {
                        record.orderPrice = record.originalPrice;
                        this.setState({
                          dataSource: [...dataSource],
                        });
                      }}>原价：{record.originalPrice}</Menu.Item>
                    </Menu>
                  }>
                    <a style={{marginLeft: 10}}><i className="iconfont icon-arrow-down"/></a>
                  </Dropdown>
                </div>
              },
            },
            {
              title: '数量',
              dataIndex: 'orderCount',
              width: 100,
              align: 'center',
              render: (text, record, index) => {
                if (!record.productId) return null;
                return <InputNumber.Count
                  selected
                  min={1}
                  max={record.stockCount || 1000000000}
                  value={text || '0'}
                  onChange={value => {
                    this.onChangeCount(index, value);
                  }}
                />
              },
            },
            {
              title: '单位',
              width: 120,
              render: (text, record, index) => {
                if (!record.productId) return null;
                return <div style={{textAlign: 'left'}}>
                  <Select value={record.orderUnitId} style={{width: '100%'}} onChange={(value, options: any) => {
                    this.onChangeUnit(index, {
                      orderUnitId: value,
                      orderUnitName: options.children,
                      orderUnitRatio: record.unitList.find(i => i.unitId === record.orderUnitId).ratio,
                    });
                  }}>
                    {record.unitList.map(item =>{
                      return <Select.Option key={item.unitId} value={item.uniId}>{item.unitName}</Select.Option>
                    })}
                  </Select>
                </div>
              },
            },
            {
              title: '金额小计',
              dataIndex: 'payAmount',
              width: 100,
              align: 'right',
              render: (value, row) => {
                if (!row.productId) return null;
                if (row.isGift === 1) return '0.00';
                return value || '--'
              }
            },
            {
              title: '备注',
              dataIndex: 'remark',
              width: 300,
              render: (text, record, index) => {
                if (!record.productId) return null;
                return <div>
                  <Input.TextArea value={text} autoSize={{maxRows: 1}} onChange={e => {
                    this.changeRemark(index, e.target.value);
                  }}/>
                </div>
              },
            },
          ]}
          rowKey={(row: any) => row.id}
        />
        <div style={{textAlign: 'right', padding: 10}}>应付金额：<span style={{color: 'red', fontSize: 18}}>{this.state.payAmount || '0.00'}</span></div>
      </Layout>
    );
  }

  public clearProducts = () => {
    this.setState({
      dataSource: [],
    });
  }

  public reset = (dataSource) => {
    this.setState({
      dataSource,
    });
  }

  private onChange = data => {
    const payAmount = data.reduce((pre, cur) => {
      return new BigNumber(cur.payAmount || 0).plus(pre).toFixed(2);
    }, '0');
    const totalAmount = data.reduce((pre, cur) => {
      return new BigNumber(cur.totalAmount).plus(pre).toFixed(2);
    }, '0');
    this.setState({
      dataSource: data,
      payAmount,
      totalAmount,
    }, () => {
      this.props.onChange(data, payAmount, totalAmount);
    });
  }
 
  private createDataSource = (index, options) => {
    const nextDataSource = [ ...this.state.dataSource ];
    const sourceData = this.state.dataSource[index];
    const targetData = { ...sourceData };
    const keys = Object.keys(options);
    keys.forEach(key => {
      targetData[key] = options[key];
    });
    targetData.payAmount = targetData.isGift ? 0 : new BigNumber(targetData.orderPrice || 0).multipliedBy(targetData.orderCount || 0).multipliedBy(targetData.orderUnitRatio || 0).toFixed(2);
    nextDataSource[index] = targetData;
    this.onChange(nextDataSource);
  }

  // 修改规格
  private onChangeSku = (index, {skuId, skuName, price, orderPrice, originalPrice, orderCount, stockUsableCount}) => {
    // 查价格
    WebApi.sale_product_latest_price({
      customerId: this.props.customerId,
      skuId,
    }).then((res: any) => {
      this.createDataSource(index, {skuId, skuName, orderPrice: res.price || orderPrice, latestPrice: res.price, price, originalPrice, orderCount, stockCount: stockUsableCount});
    }).catch(e => {
      
    });
  }

  // 修改单位
  private onChangeUnit = (index, {orderUnitId, orderUnitName, orderUnitRatio}) => {
    this.createDataSource(index, {orderUnitId, orderUnitName, orderUnitRatio});
  }

  // 修改价格
  private onChangePrice = (index, orderPrice) => {
    this.createDataSource(index, {orderPrice});
  }

  // 修改数量
  private onChangeCount = (index, orderCount) => {
    this.createDataSource(index, {orderCount});
  }

  // 修改备注
  private changeRemark = (index, remark) => {
    this.createDataSource(index, {remark});
  }

  // 删除
  private clickDelete = index => {
    const { dataSource } = this.state;
    const nextDataSource = [...dataSource];
    nextDataSource.splice(index, 1);
    this.onChange(nextDataSource);
  };

  private onModalOk = async checkedList => {
    // 选择商品时候不需要过滤，因为可能存在一个商品两个规格的情况
    const newDataSource = checkedList.map(item => {
      const newItem = { ...item };
      newItem.skuOptions = item.skuList.map(sku => {
        return {
          label: sku.skuName + `(库存:${sku.stockUsableCount})`,
          value: sku.skuId,
        }
      });
      newItem.productId = newItem.id;
      newItem.orderCount = 1;
      newItem.skuId = undefined;
      newItem.skuName = undefined;
      newItem.orderUnitId = item.unitId;
      newItem.orderUnitName = item.unitName;
      newItem.orderUnitRatio = 1;
      newItem.orderPrice = '';
      newItem.price = '';
      newItem.isGift = 0;
      return newItem;
    });
    const nextDataSource = this.state.dataSource.concat(newDataSource);
    this.setState({
      visible: false,
    });
    this.onChange(nextDataSource);
  };

  public validateProducts(): Promise<any[]> {
    if (this.state.dataSource.length === 0) {
      message.error('商品不能为空');
      return Promise.reject('商品不能为空');
    }

    // 合并商品
    const map = {};
    const combinedProducts: any[] = [];
    for (const item of this.state.dataSource) {
      const key = item.skuId + item.unitId + item.isGift;
      const product = map[key];
      if (product) {
        // 检查价格是否相等
        if (Number(item.price) !== Number(product.price)) {
          message.error('订单中存在价格不等的重复商品');
          return Promise.reject('订单中存在价格不等的重复商品');
        }
        product.orderCount = Number(item.orderCount) + Number(product.orderCount);
      } else {
        map[key] = item;
      }
    }
    for (let key in map) {
      if (!map[key].skuId) {
        const err = `“${map[key].productName}” 的规格不能为空`;
        message.error(`“${map[key].productName}” 的规格不能为空`);
        return Promise.reject(err);
      }
      combinedProducts.push(map[key]);
    }
    return Promise.resolve(combinedProducts);
  }
}